<template>
  <div>
    <v-alert
      v-if="!isLeader"
      type="error"
    >
      Only the team leader may edit the team.
    </v-alert>
    <v-alert
      v-else-if="!$online"
      type="error"
    >
      An internet connection is required to edit the team.
    </v-alert>
    <Loading
      v-else-if="loading"
      :loading="loading"
      message="Loading Team"
    />
    <Loading
      v-else-if="submitting"
      :loading="submitting"
      message="Submitting"
    />
    <div v-else>
      <v-row v-if="success || errorMessages">
        <v-col class="pb-0">
          <v-alert
            v-if="success"
            type="success"
          >
            Team updated successfully. Note that it may take a minute for your changes to take effect.
          </v-alert>
          <v-alert
            v-for="error in errorMessages"
            :key="error"
            type="error"
          >
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="team">
        <v-col>
          <v-form
            ref="form"
            @submit.prevent="submit"
          >
            <v-card>
              <v-card-title>
                {{ team.name }}: Edit
              </v-card-title>
              <v-list subheader>
                <v-list-item>
                  <v-text-field
                    v-model="teamName"
                    :disabled="submitting"
                    :rules="teamNameRules"
                    type="text"
                    label="Name"
                  />
                </v-list-item>
                <v-list-item>
                  <v-textarea
                    v-model="bio"
                    :disabled="submitting"
                    :rules="bioRules"
                    label="Bio"
                    auto-grow
                  />
                </v-list-item>
                <v-list-item>
                  <v-switch
                    v-model="acceptApplications"
                    :disabled="submitting"
                    label="Accept Applications"
                    hint="When enabled, others may apply for membership on your team."
                    persistent-hint
                  />
                </v-list-item>
                <v-list-item v-if="profile.allow_events">
                  <v-switch
                    v-model="allowPublicEvents"
                    :disabled="submitting"
                    label="Allow Public Events"
                    hint="When enabled, members may create team hosted events that non-members can view and join."
                    persistent-hint
                  />
                </v-list-item>
              </v-list>
              <v-card-text>
                <div
                  class="image-dropbox"
                  :class="{'image-selected':(image),'input-error':(imageError)}"
                >
                  <input
                    id="image"
                    class="image-input"
                    type="file"
                    accept="image/heic,image/heif,image/jpeg,image/png,.heic,.heif,.jpeg,.jpg,.png"
                    :disabled="submitting"
                    @change="imageInput($event)"
                  >
                  <div
                    v-if="!image"
                    class="text-center"
                  >
                    <v-icon
                      size="100"
                      color="primary"
                    >
                      $plus
                    </v-icon>
                    <p class="description">
                      Upload a new banner.
                    </p>
                  </div>
                  <div
                    v-if="imagePreview"
                    align="center"
                    class="imagePreview"
                  >
                    <v-btn
                      icon
                      class="clearInput"
                      @click="clearImage"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-img
                      :src="imagePreview.src"
                      height="300"
                      contain
                    />
                  </div>
                </div>
                <div
                  v-if="imageError"
                  class="hint error-message"
                >
                  {{ imageError }}
                </div>
                <div
                  v-else
                  class="hint"
                >
                  <span v-if="imageLabel">{{ imageLabel }}</span>
                  <span v-else>Images should be JPG, PNG, HEIC, or HEIF and no larger than 13MB.</span>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-btn
                      :disabled="submitting"
                      type="submit"
                      color="primary"
                      width="100%"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { allianceAPI } from '@/api'
import { checkImageValue, imageTimeout } from '@/utils/images'
import { formatBytes } from '@/utils/format'
import { mapGetters, mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'
import { teamNameRules } from '@/utils/rules'

export default {
  components: {
    Loading,
  },
  data: function() {
    return {
      acceptApplications: false,
      allowPublicEvents: false,
      bio: '',
      errorMessages: null,
      image: null,
      imageError: false,
      imageLabel: null,
      imagePreview: null,
      loading: false,
      submitting: false,
      success: null,
      teamName: '',
      teamNameRules: teamNameRules,
      bioRules: [
        v => v.length <= 4000 || 'Please restrain yourself to 4,000 characters',
      ],
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    ...mapGetters({
      getDetailsByID: 'teams/getDetailsByID',
    }),
    team() {
      return this.getDetailsByID(this.$route.params.id)
    },
    membership() {
      if (this.team) {
        return this.team.members.find(obj => obj.user == this.profile.user)
      }
      return null
    },
    isLeader() {
      return this.membership && this.membership.is_leader
    },
  },
  created: function () {
    this.updateTeam()
  },
  methods: {
    init: function () {
      if (this.team) {
        this.teamName = this.team.name
        this.bio = this.team.bio
        this.acceptApplications = this.team.accept_applications
        this.allowpublicEvents = this.team.allow_public_events
      }
    },
    updateTeam() {
      this.getDetails().finally(() => this.init())
    },
    getDetails() {
      this.loading = true
      return this.$store.dispatch('teams/getDetails', `/quest/team/${this.$route.params.id}/`)
        .finally(() => {
          this.loading = false
        })
    },
    imageInput: function(event) {
      let valid = checkImageValue(event.target.files[0])
      if (valid === true) {
        this.imageError = false
        this.image = event.target.files[0]
        this.imageLabel = `${this.image.name} (${formatBytes(this.image.size)})`
        this.imagePreview = new Image()
        this.imagePreview.src = window.URL.createObjectURL(this.image)
      } else {
        this.image = null
        this.imagePreview = null
        this.imageError = valid
      }
    },
    clearImage() {
      this.image = null
      this.imagePreview = null
      this.imageLabel = null
      this.imageError = false
    },
    submit: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = new FormData()
      data.append('name', this.teamName)
      data.append('bio', this.bio)
      data.append('accept_applications', this.acceptApplications)
      if (this.profile.allow_events) {
        data.append('allow_public_events', this.allowPublicEvents)
      }
      if (this.image) {
        data.append('image', this.image)
      }
      return allianceAPI.put(
        `/quest/team/${this.team.id}/`,
        data,
        { timeout: imageTimeout },
      )
        .then(() => {
          this.success = true
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
